'use client';
import React, { useState } from 'react';
import { Container } from '@/components/client/Container/Container';
import { Section } from '@/components/client/Section/Section';
import style from './Header.module.scss';
import logo from '@/public/icons/main_logo.webp';
import Image from 'next/image';
import { Nav } from './components/Nav/Nav';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { SideMenu } from './components/SideMenu/SideMenu';

import Button from '@/components/ui/Button/Button';
import { Icons } from '@/components/icons';
import { useModalContext } from '@/context/ModalsContext';
import { usePathname } from 'next/navigation';

export const Header = () => {
  const pathname = usePathname();
  const isAdminPath = pathname.includes('/admin');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setIsModalOpened } = useModalContext();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // console.log(isAdminPath, 'isAdminPath');

  return !isAdminPath ? (
    <Section
      styles={{
        zIndex: '10',
        background: 'transparent',
        padding: '36px 0 0 0',
        // paddintTop: 36,
      }}
      variant="primary"
    >
      <Container>
        <header className={cn(style.header, 'w-full')}>
          <div className={style.headerContainer}>
            <div className="container mx-auto flex items-center justify-between px-0">
              <div className="flex items-center space-x-4">
                <Link href={'/'}>
                  <Image src={logo} alt="Tytum Logo" style={{ width: 100 }} />
                </Link>
              </div>

              <div className="lg-custom:flex hidden flex-1 items-center justify-center">
                <Nav />
              </div>

              <div
                onClick={() => setIsModalOpened(true)}
                className="lg-custom:flex hidden items-center"
              >
                <Button variant={'main'}>Contact Us</Button>
              </div>
              {/*  <div className="ml-4 cursor-pointer">
                <Link href={'/admin/login'}>
                  <Icons.login />
                </Link>
              </div> */}

              <div className="lg-custom:hidden">
                <button onClick={toggleMenu} aria-label="Toggle Menu">
                  <Icons.burgerIcon />
                </button>
              </div>
            </div>

            <SideMenu isOpen={isMenuOpen} onClose={toggleMenu} />
          </div>
        </header>
      </Container>
    </Section>
  ) : null;
};
