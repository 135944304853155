'use client';
import React from 'react';
import { FooterSocial } from './components/FooterSocial/FooterSocial';
import { Container } from '@/components/client/Container/Container';
import { NavFooterList } from './components/NavFooterList/NavFooterList';
import { MainFooterInfo } from './components/MainFooterInfo/MainFooterInfo';
import style from './Footer.module.scss';
import { cn } from '@/lib/utils';
import { usePathname } from 'next/navigation';

const navItems = [
  {
    title: 'Services',
    items: [
      {
        title: 'Heating and Air',
        path: '/services/heating-and-air',
        items: [
          {
            title: 'Heating',
            path: '/services/heating-and-air/heating',
          },
          {
            title: 'Air Conditioning',
            path: '/services/heating-and-air/air-conditioning',
          },
          {
            title: 'Ductless Cooling & Heating',
            path: '/services/heating-and-air/cooling-and-heating',
          },
          {
            title: 'Air Conditioning Installation',
            path: '/services/heating-and-air/ac-installation',
          },
          {
            title: 'Heating System Installation',
            path: '/services/heating-and-air/heating-installation',
          },
        ],
      },
      {
        title: 'Electrical',
        path: '/services/electrical',
        items: [],
      },
      {
        title: 'Plumbing',
        path: '/services/plumbing',
        items: [],
      },
      {
        title: 'Remodeling',
        path: '/services/remodeling',
        items: [],
      },
      {
        title: 'New Construction',
        path: '/services/construction',
        items: [],
      },
      {
        title: 'Real Estate Brokerage',
        path: '/services/brokerage',
        items: [],
      },
    ],
  },

  {
    title: 'About Us',
    items: [
      {
        title: 'Careers',
        path: '/company/careers',
        items: [],
      },
      {
        title: 'FAQ',
        path: '/company/faq',
        items: [],
      },

      {
        title: 'Privacy and Cookie Policy',
        path: '/privacy',
        items: [],
      },

      {
        title: 'Terms and Conditions',
        path: '/terms',

        items: [],
      },
    ],
  },
  {
    title: 'Other',
    items: [
      {
        title: 'Rebates',
        path: '/rebates',
        items: [],
      },
      {
        title: 'Testimonials',
        path: '/testimonials',
        items: [],
      },
      {
        title: 'Partners',
        path: '/partners',
        items: [],
      },
    ],
  },
  {
    title: 'Contact',
    items: [
      {
        title: 'contact@tytum.com',
        items: [],
        type: 'email',
        path: 'contact@tytum.com',
      },
      {
        title: '(619) 349-4397',
        items: [],
        type: 'phone',
        path: '+16193494397',
      },
      {
        title: `16950 Via De Santa Fe, 
Suite 5060-136, 
Rancho Santa Fe, CA 92091`,
        items: [],
        type: 'location',
        path: 'https://www.google.com/maps/place/16950+Via+De+Santa+Fe+5060+136,+Rancho+Santa+Fe,+CA+92091,+USA/@33.0186248,-117.2026129,1200m/data=!3m2!1e3!4b1!4m5!3m4!1s0x80dc0995fb042729:0xdf2dfe3a2baee035!8m2!3d33.0186248!4d-117.2026129?hl=en&entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D',
      },
    ],
  },
];

export const Footer = () => {
  const pathname = usePathname();
  const isAdminPath = pathname.includes('/admin');
  return !isAdminPath ? (
    <footer
      className={cn(
        'text-muted-foreground w-full text-center text-sm',
        style.footer
      )}
    >
      <Container>
        <div className={style.listContainer}>
          <MainFooterInfo />
          <NavFooterList items={navItems} />
        </div>

        <div className={style.line}></div>

        <FooterSocial />
      </Container>
    </footer>
  ) : null;
};
